import request from '@/utils/request'
import { version } from '/package.json'
// import ky from 'ky'
const { dataset } = document.currentScript
// request(`${process.env.VUE_APP_WEBCHAT_URL}/files.json?${Date.now()}`)
// ky.get(`${process.env.VUE_APP_WEBCHAT_URL}/files.json?${version}`)
//   .json()

// todo: delete if do not using iframe
// const WEB_CHAT = Object.freeze({
//   CONTAINER_ID: 'live-chat-container',
//   BTN_ID: 'live-chat-btn',
// })

window.quidget = { isDestroyed: false }
request(`${process.env.VUE_APP_WEBCHAT_URL}/files.json?v=${version}`)
  .then(files => {
    if (!files || !Array.isArray(files) || files.length === 0) {
      throw 'files not found'
    }

    const js = files.find(name => /^quidget\.[a-f0-9]{8,16}\.js$/.test(name))
    if (!js) {
      throw 'script not found'
    }

    // insert HTML variant
    const app = document.createElement('script')
    app.async = true
    app.src = `${process.env.VUE_APP_WEBCHAT_URL}/${js}`
    ;(document.head || document.documentElement).appendChild(app)
    app.onload = () => {
      // document.addEventListener('DOMContentLoaded', event => {
      //   return window.quidget.init(dataset)
      // })

      if (document.readyState === 'complete') {
        return !window.quidget.isDestroyed && window.quidget.init(dataset)
      } else {
        document.onreadystatechange = () => {
          if (document.readyState === 'complete') {
            return !window.quidget.isDestroyed && window.quidget.init(dataset)
          }
        }
      }
    }

    // start todo: delete if do not using iframe
    // insert iframe variant
    // const prev = document.getElementById(WEB_CHAT.CONTAINER_ID)
    // if (prev) {
    //   prev.remove()
    // }
    //
    // const qFrame = document.createElement('iframe')
    // qFrame.setAttribute('id', WEB_CHAT.CONTAINER_ID)
    // qFrame.setAttribute('name', 'quidget')
    // qFrame.setAttribute('width', '68px')
    // qFrame.setAttribute('height', '140px')
    // qFrame.setAttribute(
    //   'style',
    //   'position:fixed;border:none;background-color:transparent;bottom:32px;right:36px;margin-top:60px;z-index:100000'
    // )
    //
    // qFrame.onload = () => {
    //   const qWindow = qFrame.contentWindow
    //   const app = qWindow.document.createElement('script')
    //   app.async = true
    //   app.src = `${process.env.VUE_APP_WEBCHAT_URL}/${js}`
    //   ;(qWindow.document.head || qWindow.document.documentElement).appendChild(app)
    //   app.onload = () => qWindow.quidget.init(dataset, qFrame)
    // }
    // ;(document.body || document.documentElement).appendChild(qFrame)
    // end todo: delete if do not using iframe
  })
  .catch(err => console.warn(`💬 fail on load quidget ${JSON.stringify(err)}`, err))
